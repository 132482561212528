/* eslint-disable */
import React, { useEffect, useState } from "react";
import TimelineComponent from "./TimelineComponent";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { useDoin } from "../ContextProviders/MainContextProvider";
import axios from "axios";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const TimelinePage = () => {
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState(5);
  const { user_token } = useDoin();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(["ALL"]);
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const fetchTimelineData = async () => {
    console.log("fetchTimelineData", year, month);
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/timeline?token=${user_token}&year=${year}&month=${month}`
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLocationSwitchChange = (option) => {
    if (option === "ALL") {
      setSelected((prevSelected) =>
        prevSelected.includes("ALL") ? [] : ["ALL"]
      );
    } else {
      setSelected((prevSelected) => {
        if (prevSelected.includes(option)) {
          return prevSelected.filter(
            (item) => item !== option && item !== "ALL"
          );
        } else {
          const updatedSelection = prevSelected.filter(
            (item) => item !== "ALL"
          );
          return [...updatedSelection, option];
        }
      });
    }
  };

  useEffect(() => {
    console.log("Fetching data for year:", year, "month:", month);
    setData([]);
    const fetchData = async () => {
      const data = await fetchTimelineData();
      setData(data);
    };
    fetchData();
  }, [year, month]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        marginBottom={2}
        marginTop={2}
      >
        <FormControl variant="outlined">
          <InputLabel id="year-label">Year</InputLabel>
          <Select labelId="year-label" value={year} onChange={handleYearChange}>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            value={month}
            onChange={handleMonthChange}
          >
            <MenuItem value={1}>January</MenuItem>
            <MenuItem value={2}>February</MenuItem>
            <MenuItem value={3}>March</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>June</MenuItem>
            <MenuItem value={7}>July</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>October</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>December</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            border: 1,
            borderColor: "black",
            marginLeft: 2,
          }}
        >
          {data?.locations && (
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <Switch
                checked={selected.includes("ALL")}
                onChange={() => handleLocationSwitchChange("ALL")}
                inputProps={{ "aria-label": "Toggle all" }}
              />
              <Typography>All</Typography>
            </Box>
          )}

          {data?.locations?.map((location) => (
            <Box
              key={location}
              sx={{
                marginLeft: 2,
              }}
            >
              <Switch
                checked={selected.includes(location)}
                onChange={() => handleLocationSwitchChange(location)}
                inputProps={{ "aria-label": `Toggle ${location}` }}
              />
              <Typography>{location}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <TimelineComponent data={data} selected={selected} />
    </>
  );
};

export default TimelinePage;
