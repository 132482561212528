/* eslint-disable */

import React from "react";
import { Box, Typography } from "@mui/material";

import { addDays, format } from "date-fns";
// import propTypes from "prop-types";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";

// import axios from "axios";
// import { useDoin } from "../ContextProviders/MainContextProvider";
// eslint-disable-next-line
const DAY_WIDTH = 100;
const DAY_WIDTHpx = `${DAY_WIDTH}px`;
const Height = 50;
const Heightpx = `${Height}px`;

// eslint-disable-next-line
const compute_left = (bookings, booking, index) => {
  let start = DAY_WIDTH * booking.start;
  if (index === 0) {
    return `${start}px`;
  } else {
    // base = 0;
    // const start_position = bookings[index - 1].end - booking.start;
    return `${start - bookings[index - 1].end * DAY_WIDTH}px`;
  }
};

const HeaderComponent = ({ start_date, n_days, selected, setSelected }) => {
  const startDate = new Date(start_date);
  const dates = Array.from({ length: n_days }, (_, i) => addDays(startDate, i));

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: 0 }}>
      <Box
        sx={{
          minWidth: DAY_WIDTHpx,
          maxWidth: DAY_WIDTHpx,
          minHeight: Heightpx,
          maxHeight: Heightpx,
          backgroundColor: "primary.main",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          border: 1,
          borderColor: "black",
        }}
      >
        <Typography sx={{ transform: "rotate(-45deg)", fontSize: 10 }}>
          Dates
        </Typography>
      </Box>
      {dates.map((date, index) => (
        <Box
          key={index}
          sx={{
            minWidth: `${DAY_WIDTH - 4}px`,
            maxWidth: `${DAY_WIDTH - 4}px`,
            height: Heightpx,
            backgroundColor: "primary.main",
            marginLeft: "2px",
            marginRight: "2px",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            zIndex: 1,
            border: 1,
            borderColor: "black",
          }}
        >
          <Typography sx={{ transform: "rotate(90deg)", fontSize: 10 }}>
            {format(date, "yyyy-MM-dd")}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
const ResourceRow = ({ resource_bookings, resource_name, selected }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {/* Resource name */}
      <Box
        sx={{
          position: "sticky",
          left: 0,
          minWidth: DAY_WIDTHpx,
          maxWidth: DAY_WIDTHpx,
          height: Heightpx,
          backgroundColor: "primary.main",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginBottom: 0,
          zIndex: 1,
          border: 1,
          borderColor: "black",
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: 12 }}>
          {resource_name}
        </Typography>
      </Box>
      {resource_bookings.map((booking, index) => {
        // This is to hide the booking if it is not in filter
        let bcolor = booking.bar_color;
        let borderColor = "black";
        let textColor = "black";

        if (
          selected.length > 0 &&
          !selected.includes("ALL") &&
          !selected.includes(booking.pickup_location) &&
          !selected.includes(booking.dropoff_location)
        ) {
          bcolor = "transparent";
          textColor = "transparent";
          borderColor = "transparent";
        }

        const marginLeft = compute_left(resource_bookings, booking, index);
        return (
          <Box
            key={index}
            sx={{
              height: Heightpx,
              backgroundColor: bcolor, // Use booking.bar_color as the background color
              borderRadius: "10px",
              border: 1,
              borderColor: borderColor,
              minWidth: `${booking.diff * DAY_WIDTH}px`,
              maxWidth: `${booking.diff * DAY_WIDTH}px`,
              marginLeft: marginLeft,
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: 12, color: textColor }}
            >
              {booking["bar_info"]}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const DayVerticalGridLines = () => {
  const lines = Array.from({ length: 39 }, (_, i) => i * DAY_WIDTH);
  return (
    <>
      {lines.map((left, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: 0,
            left: `${left}px`,
            width: "1px",
            height: "100%",
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: -1,
          }}
        />
      ))}
    </>
  );
};

const HorizontalGridLines = ({ number_of_resources }) => {
  const lines = Array.from(
    { length: number_of_resources },
    (_, i) => i * Height
  );
  const totalWidth = 38 * DAY_WIDTH; // Calculate the total width
  return (
    <>
      {lines.map((top, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            left: 0,
            top: `${top}px`,
            width: `${totalWidth}px`, // Set the width to the total width
            height: "1px",
            backgroundColor: "black",
            opacity: 0.5,
          }}
        />
      ))}
    </>
  );
};
// eslint-disable-next-line
const QuarterDayVerticalGridLines = () => {
  const days = Array.from({ length: 100 }, (_, i) => i * 100);
  const parts = 12;
  return (
    <>
      {/* eslint-disable-next-line */}
      {days.map((dayStart, index) => {
        return Array.from({ length: parts }, (_, i) => {
          const left = dayStart + (i * 100) / parts;
          const quarter = i;
          const hours = (quarter * 24) / parts;
          const time = `${hours.toString().padStart(2, "0")}:00`;
          return (
            <Box
              key={left}
              sx={{
                position: "absolute",
                top: 0,
                left: `${left}px`,
                width: "1px",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.5,
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "80%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(90deg)",
                  fontSize: "8px",
                  color: "black",
                }}
              >
                {time}
              </Typography>
            </Box>
          );
        });
      })}
    </>
  );
};
const TimelineComponent = ({ data, selected }) => {
  if (!data["timeline"]) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        position: "relative",
        // width: `${containerWidth}px`,
        overflowX: "auto",
        // transform: "scale(0.5)", // 80% of the original size
      }}
    >
      <HeaderComponent start_date={data["min_date"]} n_days={35} />

      {data["timeline"].map((item, index) => (
        <ResourceRow
          key={index}
          resource_bookings={item["data"]}
          resource_name={item["resource"]}
          selected={selected}
        />
      ))}
      <DayVerticalGridLines />
      <HorizontalGridLines number_of_resources={data["timeline"].length} />
      {/* <QuarterDayVerticalGridLines /> */}
    </Box>
  );
};

HeaderComponent.propTypes = {
  start_date: PropTypes.string,
  n_days: PropTypes.number,
};

HorizontalGridLines.propTypes = {
  number_of_resources: PropTypes.number,
};

ResourceRow.propTypes = {
  resource_bookings: PropTypes.array,
  resource_name: PropTypes.string,
};

TimelineComponent.propTypes = {
  data: PropTypes.object,
};

export default TimelineComponent;
